import React, { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import imagen1 from './galeria/26042024.webp';
import imagen2 from './galeria/05042024.webp';
import imagen3 from './galeria/220324.webp';
import imagen4 from './galeria/08032024.webp';
import imagen5 from './galeria/23022024.webp';
import imagen6 from './galeria/26012024.webp';
import imagen7 from './galeria/15122023.webp';
import imagen8 from './galeria/24112023.webp';
import imagen9 from './galeria/08112023.webp';
import imagen10 from './galeria/27102023.webp';
import imagen11 from './galeria/11102023.webp';
import imagen12 from './galeria/6.webp';
import imagen13 from './galeria/7.webp';


import '../fonts/fonts.css';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const Tab = styled.button`
  background: ${(props) => (props.active ? '#00236F' : '#575756')};
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Anton', sans-serif;
  margin: 0 5px;
  border-radius: 5px;

  &:hover {
    background: #00389e;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover div {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 35, 111, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Anton', sans-serif;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const temporada3 = [
  { src: imagen1, link: 'https://drive.google.com/drive/folders/1WMqGpumBNDn4aJv82UTW5TIPsZOhj_gM?usp=drive_link', text: '26/04/2024' },
  { src: imagen2, link: 'https://drive.google.com/drive/folders/1wyZeRI6GmvPeUFT8qJgw-6BHHR3UAE7S?usp=drive_link', text: '05/04/2024' },
  { src: imagen3, link: 'https://drive.google.com/drive/folders/12QA5vauFQLR7nTm4s3ReR3e0R1Bf2CKy?usp=drive_link', text: '22/03/2024' },
  { src: imagen4, link: 'https://drive.google.com/drive/folders/1UlPyz5Ehl3iWDlrH24HTOALGFNC3do0Q?usp=drive_link', text: '08/03/2024' },
  { src: imagen5, link: 'https://drive.google.com/drive/folders/1qdzPFCVI0hDQVRLBFZbF8m-g6IRffQ73?usp=drive_link', text: '23/02/2024' },
  { src: imagen6, link: 'https://drive.google.com/drive/folders/1PeJqUMjPKF0oVenZVp21rvWI1tNNopOI?usp=drive_link', text: '26/01/2024' },
  { src: imagen7, link: 'https://drive.google.com/drive/folders/1IezZR8pdyHcYoFz1EQvh3UeSGIK42V9C?usp=drive_link', text: '15/12/2023' },
  { src: imagen8, link: 'https://drive.google.com/drive/folders/1U3mJ0rxnLiq0T4sfxZC6ia_CqpHq18_h?usp=drive_link', text: '24/11/2023' },
  { src: imagen9, link: 'https://drive.google.com/drive/folders/1vnO0QDO0OiVUoHGHAyTIAjMEzvw9m2Y4?usp=drive_link', text: '08/11/2023' },
  { src: imagen10, link: 'https://drive.google.com/drive/folders/13_L9glXFVddP-KcceaS24_34jGzTDBPV?usp=drive_link', text: '27/10/2023' },
  { src: imagen11, link: 'https://drive.google.com/drive/folders/1upK-YDEdWutMoJ_f9fdQ6BMz_HM1U-fi?usp=drive_link', text: '11/10/2023' },
];

const temporada4 = [
  { src: imagen12, link: 'https://drive.google.com/drive/folders/1T4GXeX98tZn5eP1xkOWUVDhcjOzpke2L?usp=drive_link', text: '20/12/2024' },
  { src: imagen13, link: 'https://drive.google.com/drive/folders/11yb95Pp7_XKTyC_iJcZ81duI-5V_Uvsz?usp=drive_link', text: '15/11/2024' },
];

function Media() {
  const [activeTab, setActiveTab] = useState('Temporada 3');

  const images = activeTab === 'Temporada 3' ? temporada3 : temporada4;

  return (
    <PageContainer>
      <Navbar />
      <Content style={{ marginTop: '1rem' }}>
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'Font',
            color: '#575756',
            fontSize: '20px',
            marginBottom: '1rem',
          }}
        >
          MEDIA
        </h1>
        <StyledSeparator />
        <TabContainer>
          <Tab style={{fontFamily: "Font"}}  active={activeTab === 'Temporada 3'} onClick={() => setActiveTab('Temporada 3')}>
            TEMPORADA 3
          </Tab>
          <Tab style={{fontFamily: "Font"}} active={activeTab === 'Temporada 4'} onClick={() => setActiveTab('Temporada 4')}>
            TEMPORADA 4
          </Tab>
        </TabContainer>
        <Grid>
          {images.map((image, index) => (
            <a key={index} href={image.link} style={{ textDecoration: 'none' }}>
              <GridItem>
                <img src={image.src} alt={`Imagen ${index + 1}`} />
                <Overlay>{image.text}</Overlay>
              </GridItem>
            </a>
          ))}
        </Grid>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default Media;
