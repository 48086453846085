import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'; 
import styled from 'styled-components';
import { ReactComponent as SVG1 } from './svg/Instagram.svg';
import { ReactComponent as SVG2 } from './svg/Tiktok.svg';
import { ReactComponent as SVG3 } from './svg/Spotify.svg';
import { ReactComponent as CustomSVG } from './svg/separadormvl.svg'; 

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
`;

const SVGContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  @media (min-width: 769px) {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledSVG = styled.div`
  width: 250px;
  height: auto;
  padding: 1.2rem;

  @media (max-width: 768px) {
    width: 200px;
    height: auto;
    padding: 0.5rem;
  }
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 5rem;

    @media (max-width: 768px) {
  margin-top: 2rem;
  }

`;

const ContactItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 10px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
      width: 60%;

  }

  span {
    font-family: "Font";
    font-size: 10px;

    &.bold {
      font-weight: bold;
    }

    @media (min-width: 1025px) {
      font-size: 14px;
    }
  }
`;

const CustomSVGStyled = styled(CustomSVG)`
  width: 85%;
  margin: 10px 0;
  width: 100%;
  @media (min-width: 1025px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

function Contacto() {
  return (
    <PageContainer>
      <Navbar />
      <Content>
        <h1 style={{
          display: "flex",
          justifyContent: "center",
          fontFamily: "Font",
          color: "#575756",
          fontSize: "20px",
          marginBottom: "1rem"
        }}>
          CONTACTO
        </h1>

        <ContactInfoContainer>
        <CustomSVGStyled />
          <ContactItem style={{marginTop: "1rem"}}>
            <span className="bold">CONSIGUE TU CARNET</span>
            <span>CARNETS@ALERONCLUB.COM</span>
          </ContactItem>

          <CustomSVGStyled />
          <ContactItem style={{marginTop: "1rem"}}>
            <span className="bold">SHOP</span>
            <span>SHOP@ALERONCLUB.COM</span>
          </ContactItem>
          <CustomSVGStyled />

          <ContactItem style={{marginTop: "1rem"}}>
            <span className="bold">INCIDENCIAS TICKETS</span>
            <span>SHOP@ALERONCLUB.COM</span>
          </ContactItem>

          <CustomSVGStyled />

          <ContactItem style={{marginTop: "1rem"}}>
            <span className="bold">INFO / BRANDS / ARTISTAS</span>
            <span>ALERONCLUB@GMAIL.COM</span>
          </ContactItem>
          <CustomSVGStyled />
        </ContactInfoContainer>

        <SVGContainer>
          <a href="https://www.instagram.com/aleronclub" target="_blank" rel="noopener noreferrer">
            <StyledSVG>
              <SVG1 />
            </StyledSVG>
          </a>
          <a href="https://www.tiktok.com/@aleronclub" target="_blank" rel="noopener noreferrer">
            <StyledSVG>
              <SVG2 />
            </StyledSVG>
          </a>
          <a href="https://open.spotify.com/intl-es/artist/1m9WPOccw8sizsVYUhSVjZ" target="_blank" rel="noopener noreferrer">
            <StyledSVG>
              <SVG3 />
            </StyledSVG>
          </a>
        </SVGContainer>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default Contacto;
